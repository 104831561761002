const columns = (renderCell, renderActions, renderEdit, renderWellsNameEditCell, renderValueCell, renderValueNumberCell, renderRigNameEditCell, renderHydraulicCell) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 350,
    editable: true,
    renderEditCell: renderWellsNameEditCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'rig',
    headerName: 'Rig',
    width: 350,
    renderEditCell: renderRigNameEditCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'operator',
    headerName: 'Operator',
    width: 200,
    renderEditCell: renderValueCell,
    headerAlign: 'center',
    editable: true,
    align: 'center'
  },
  {
    field: 'latitude',
    headerName: 'Latitute',
    width: 150,
    renderEditCell: renderValueNumberCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'longitude',
    headerName: 'Longitude',
    width: 150,
    renderEditCell: renderValueNumberCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'surveys',
    headerName: 'Surveys',
    width: 110,
    renderCell: renderEdit,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'geometry',
    headerName: 'Geometry',
    width: 110,
    renderCell: renderEdit,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'drill_string',
    headerName: 'Drillstring',
    width: 110,
    renderCell: renderEdit,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'drilling_fluid',
    headerName: 'Drilling Fluid',
    width: 110,
    renderCell: renderEdit,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'hydraulic',
    headerName: 'Hydraulics',
    renderCell: renderHydraulicCell,
    headerAlign: 'center',
    width: 110,
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 120,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];

export default columns;
