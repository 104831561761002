import React, { useEffect, useRef, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef, gridFilteredSortedRowEntriesSelector } from '@mui/x-data-grid-pro';
import pinGreen from '../../assets/images/pin_green.png';
import pinRed from '../../assets/images/pin_red.png';
import pinAmber from '../../assets/images/pin_amber.png';
import { Icon } from 'leaflet';
import Datagrid from '../ui/Datagrid';
import columns from './table_config';
import { Grid, IconButton } from '@mui/material';
import { renderCell } from '../ui/Datagrid/table_config';
import PropTypes from 'prop-types';
import { createNewWell, deleteWell, listWells, updateWellDetails, updateWells } from '../../store/actions/wells';
import { SaveRounded, EditRounded, DeleteRounded, DirectionsRunRounded } from '@mui/icons-material';
import { MAP_TYPES, REDUX_ACTIONS } from '../../shared/config/constants';
import SurveyModal from '../survey';
import Map from '../map';
import { listRigs } from '../../store/actions/rigs';
import SelectAutoComplete from '../ui/SelectAutoCompleteCell';
import GeometryModal from '../geometry';
import store from '../../store/store';
import DrillStringModal from '../drillString';
import DrillFluidModal from '../drillFluid';
import { enqueueSnackbar } from '../../store/actions/snackbar';
import SelectEditInputTextField from '../ui/SelectEditInputTextField';
import HydraulicsModal from '../ui/HydraulicsModalDialog';
import DeleteConfirmationModal from '../deleteConfirmationModal';
const pins = {
  default: pinRed,
  green: pinGreen,
  red: pinRed,
  amber: pinAmber,
};
// adding static well data temporarily for page content - remove once backend flow for wells is ready
const Wells = () => {
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const wells = useSelector((state) => state.wells);
  const rigs = useSelector((state) => state.rigs);
  const user = useSelector((state) => state.currentUser);
  const mapRef = useRef(null);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [surveyData, setSurveyData] = useState();
  const mapType = process.env.REACT_APP_WELLS_PAGE_MAP;
  const [gmapRowId, setGmapRowId] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [showGeometryModal, setShowGeometryModal] = useState(false);
  const [geometryData, setGeometryData] = useState();
  const [wellsData, setWellsData] = useState([]);
  const [showDrillStringModal, setShowDrillStringModal] = useState(false);
  const [drillStringData, setDrillStringData] = useState();
  const [showDrillFluidModal, setShowDrillFluidModal] = useState(false);
  const [drillFluidData, setDrillFluidData] = useState();
  const [rigOption, setRigOption] = useState([]);
  const [isHydraulicsDialogOpen, setIsHydraulicsDialogOpen] = useState(false);
  const [currentJob, setCurrentJob] = useState({});
  const [deleteAction, setDeleteAction] = useState(false);
  const [deleteRow, setDeleteRow] = useState();

  useEffect(() => {
    // this effect is used to update the map size when the user changes viewport to widescreen
    if (!mapRef?.current?.invalidateSize) return;
    mapRef.current.invalidateSize();
  }, [user.preferences.aspectRatioPage]);

  useEffect(() => {
    if (!wells.loading) {
      dispatch(listWells());
    }
  }, []);

  useEffect(() => {
    if (wells.data.length) {
      const wellData = wells.data;
      wellData.forEach(well => {
        const rig = rigOption.find(rigData => rigData.WellID === well.wellInfo_Id);
        if (rig) {
          well.operator = well?.operator ?? rig.ENVOperator;
          well.rig = well?.rig ?? rig.RigName_Number;
        }
        well.wellsInfoId = well._id;
      });
      setWellsData(wellData);
    }
  }, [wells.data]);

  useEffect(() => {
    if (!rigs.loading && rigs.data.length === 0) {
      dispatch({
        type: REDUX_ACTIONS.RIGS_LIST_LOADING,
      });
      dispatch(listRigs());
    }
  }, []);

  useEffect(() => {
    if (rigs?.data?.length) {
      const data = rigs.data.filter(record => record.LeaseName !== 'UNKNOWN' && record.WellNumber !== 'UNKNOWN');
      const uniqueRecords = [];
      const seenKeys = new Set();
      data.forEach(record => {
        const key = `${record.LeaseName}-${record.WellNumber}`;
        if (!seenKeys.has(key)) {
          seenKeys.add(key);
          uniqueRecords.push(record);
        }
      });
      setRigOption(uniqueRecords);
    }
  }, [rigs.data]);

  useEffect(() => {
    if (wells?.data[0]?.name === '') {
      const id = wells?.data[0]._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 1
        });
        apiRef.current.startCellEditMode({ id, field: 'name' });
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'name');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [wells.data.length]);

  const getPinColor = (status) => {
    return status ? 'green' : 'red';
  };

  const showSurveyDialog = (surveyData) => {
    setShowSurveyModal(true);
    setSurveyData(surveyData);
  };

  const showDrillStringDialog = (drillStringData) => {
    setShowDrillStringModal(true);
    setDrillStringData(drillStringData);
  };

  const showDrillFluidDialog = (drillFluidData) => {
    setShowDrillFluidModal(true);
    setDrillFluidData(drillFluidData);
  };
  const showGeometryDialog = (geometryData) => {
    setShowGeometryModal(true);
    setGeometryData(geometryData);
  };

  const getMapPin = (status) => {
    const pinColor = getPinColor(status);
    const pinIcon = pinColor ? pins[pinColor] : pins.default;
    return (
      pinIcon &&
      new Icon({
        iconUrl: pinIcon,
        iconSize: [15, 25],
        popupAnchor: [0, -5],
      })
    );
  };

  const getGoogleMapPin = (status) => {
    const pinColor = getPinColor(status);
    const pinIcon = pinColor ? pins[pinColor] : pins.default;
    return {
      url: pinIcon,
      // eslint-disable-next-line no-undef
      scaledSize: new google.maps.Size(15, 25),
      // eslint-disable-next-line no-undef
      size: new google.maps.Size(15, 25)
    };
  };

  const handleSave = (row) => {
    if (row?.isNewRow) {
      dispatch(createNewWell(row));
    } else {
      dispatch(updateWellDetails(row));
    }
  };

  const handleDelete = (row) => {
    if (row?.isNewRow) {
      dispatch(updateWells(row._id));
    } else {
      dispatch(deleteWell(row));
    }
    setDeleteAction(false);
    setDeleteRow({});
  };

  const renderActions = ({
    row
  }) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
        disabled={!row?.name || !row?.latitude || !row?.longitude || wells.saveWellsDetails || !row?.rig || !row?.operator}
        variant="solid"
        onClick={() => {
          handleSave(row);
        }}
      >
        <SaveRounded />
      </IconButton>
      <IconButton
        variant="solid"
        onClick={() => {
          setDeleteRow(row);
          setDeleteAction(true);
        }}
      >
        <DeleteRounded />
      </IconButton>
      </Grid>
    );
  };

  const updateData = (id, field, value) => {
    const { wells } = store.getState();
    if (value?.WellID !== '' && wells.data.some(well => well.wellInfo_Id === value.WellID) && field === 'name') {
      dispatch(
        enqueueSnackbar(
          'Please select different Well',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return;
    }

    if (value?.WellID === '' && wells.data.some(well => well?.name?.toLowerCase().trim() === value?.LeaseName?.toLowerCase().trim()) && field === 'name') {
      dispatch(
        enqueueSnackbar(
          'Well name already exists, Please enter a different Well name',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return;
    }
    const updatedProperties = wells.data.map(item => {
      if (item._id === id) {
        return {
          ...item,
          [field]: value?.LeaseName + ' ' + value.WellNumber,
          latitude: value?.RigLatitudeWGS84?.toString() ?? item?.latitude,
          longitude: value?.RigLongitudeWGS84?.toString() ?? item?.longitude,
          lastUpdate: new Date(item?.lastUpdate)?.toLocaleString() ?? new Date()?.toLocaleString(),
          wellInfo_Id: value?.WellID,
          jobId: item?.jobId || '',
          operator: value?.ENVOperator ?? item?.operator,
          rig: value?.RigName_Number ?? item?.rig
        };
      }
      return item;
    });
    setWellsData(updatedProperties);
    dispatch({
      type: REDUX_ACTIONS.WELLS_LIST_SUCCESS,
      payload: {
        data: updatedProperties,
        paginatedElements: updatedProperties.length,
      },
    });
  };

  const updateFieldData = (id, field, value) => {
    const { wells } = store.getState();
    const parsedValue = field === 'latitude' || field === 'longitude' ? parseFloat(value) : value;
    if ((field === 'latitude' || field === 'longitude') && isNaN(parsedValue)) {
      return;
    }
    const updatedProperties = wells.data.map(item => {
      if (item._id === id) {
        return {
          ...item,
          [field]: parsedValue
        };
      }
      return item;
    });
    setWellsData(updatedProperties);
    dispatch({
      type: REDUX_ACTIONS.WELLS_LIST_SUCCESS,
      payload: {
        data: updatedProperties,
        paginatedElements: updatedProperties.length,
      },
    });
  };

  const showHydraulicsDialog = () => {
    setIsHydraulicsDialogOpen(true);
  };

  const renderHydraulicCell = (_props) => {
    return (
      <>
        <Grid container justifyContent="center">
         { _props.row.hydraulicsInputs && <IconButton
            variant="solid"
            key="hydraulics"
            onClick={ () => {
              const jobData = {
                _id: _props?.row?.jobId,
                fromWells: true,
                Rig: _props.row.rig,
                wellsInfoId: _props.row.wellsInfoId
              };
              setCurrentJob(jobData);
              showHydraulicsDialog();
            }}
          >
            <DirectionsRunRounded />
          </IconButton>}
        </Grid>
      </>
    );
  };

  const renderEdit = (row) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
        disabled={row?.row?.isNewRow}
        variant="solid"
        onClick={() => {
          if (row.field === 'surveys') {
            showSurveyDialog(row);
          }
          if (row.field === 'drill_string') {
            showDrillStringDialog(row);
          }
          if (row.field === 'drilling_fluid') {
            showDrillFluidDialog(row);
          }
          if (row.field === 'geometry') {
            showGeometryDialog(row);
          }
        }}
      >
        <EditRounded />
      </IconButton>
      </Grid>
    );
  };

  const renderValueCell = (params) => {
    return <SelectEditInputTextField {...params} updateData={updateFieldData} />;
  };

  const renderValueNumberCell = (params) => {
    return <SelectEditInputTextField numberFormatOnly={true} {...params} updateData={updateFieldData} />;
  };

  const renderWellsNameEditCell = (params) => {
    return <SelectAutoComplete closeCellAfterSelect={true} {...params} data={rigOption} updateData={updateData}/>;
  };

  const renderRigNameEditCell = (params) => {
    return <SelectAutoComplete type='rig' closeCellAfterSelect={true} {...params} data={rigOption} updateData={updateData}/>;
  };

  const [columnsList, setColumnsList] = useState(columns(renderCell, renderActions, renderEdit, renderWellsNameEditCell, renderValueCell, renderValueNumberCell, renderRigNameEditCell, renderHydraulicCell));

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  const handleVisibiltyModel = (newModel) => {
    setColumnVisibilityModel(newModel);
  };

  useEffect(() => {
    if (!wells.loading && wells.data.length) {
      setColumnsList(columns(renderCell, renderActions, renderEdit, renderWellsNameEditCell, renderValueCell, renderValueNumberCell, renderRigNameEditCell, renderHydraulicCell));
    }
  }, [wells.loading, rigOption]);

  return (
    <Grid container justifyContent="center">
      <Map
        mapType={mapType}
        data={wells}
        mapData={wells.marker_data}
        markerData={wells.marker_data}
        latitudeField={'latitude'}
        longitudeField={'longitude'}
        mapPinField1={'hydraulicsInputs'}
        popUpLabel={['name']}
        getGoogleMapPin={getGoogleMapPin}
        getMapPin={getMapPin}
        gmapRowId={gmapRowId}
        rowId={rowId}
        excludePopupLabel
      />
      <Grid item xs={12}>
        { showSurveyModal && <SurveyModal
          onCloseClick={() => setShowSurveyModal(false)}
          isOpen={showSurveyModal}
          surveyData={surveyData}
            />
        }
        {
          showDrillFluidModal && <DrillFluidModal
          onCloseClick={() => setShowDrillFluidModal(false)}
          isOpen={showDrillFluidModal}
          drillFluidData={drillFluidData}
        />
        }
        {showDrillStringModal && <DrillStringModal
          onCloseClick={() => setShowDrillStringModal(false)}
          isOpen={showDrillStringModal}
          drillStringData={drillStringData}
        />}
        {showGeometryModal && <GeometryModal
          onCloseClick={() => setShowGeometryModal(false)}
          isOpen={showGeometryModal}
          geometryData={geometryData}
          />
        }
        {isHydraulicsDialogOpen && <HydraulicsModal
          isOpen={isHydraulicsDialogOpen}
          onCloseClick={() => setIsHydraulicsDialogOpen(false)}
          selectedJob={currentJob}
      />
        }
        {
          deleteAction && <DeleteConfirmationModal
            open={deleteAction}
            deleteRow={deleteRow}
            handleDelete={handleDelete}
            handleClose={setDeleteAction}
          />
        }
        <Datagrid
          apiRef={apiRef}
          data={wellsData}
          getRowId={(row) => row._id}
          columns={columnsList}
          loading={wells.loading || rigs.loading }
          sx={{
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold !important',
            },
            '.MuiDataGrid-cell': {
              fontSize: 'medium',
              fontWeight: 'bold !important'
            },
          }}
          onRowClick={({ row: well }) => {
            if (mapType === MAP_TYPES.GOOGLEMAP) {
              setGmapRowId(well._id);
            } else {
              setRowId(well);
            }
          }}
          onRowDoubleClick={() => {
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            handleVisibiltyModel(newModel);
          }}

          experimentalFeatures={{ newEditingApi: true }}
          toolbar={{
            options: {
              columns: true,
              filters: true,
              export: false,
            },
          }}
          onFilterModelChange={() => {
            setTimeout(() => {
              const filterData = gridFilteredSortedRowEntriesSelector(apiRef.current.state, apiRef.current.instanceId).map(item => {
                return { ...item.model };
              });
              if (filterData) {
                dispatch({
                  type: REDUX_ACTIONS.WELLS_MARKER_FILTER,
                  payload: {
                    data: filterData,
                  }
                });
              }
            }, 300);
          }}
          onCellEditCommit={({ id, value, field }) => {
            updateData(id, field, value);
          }}
          editMode="cell"
        />
      </Grid>
    </Grid>
  );
};
Wells.propTypes = {
  row: PropTypes.object,
};
export default Wells;
