export const USER_TYPE = {
  PREMIUM_ADMIN: 'PREMIUM_ADMIN',
  CUSTOMER_MANAGER: 'CUSTOMER_MANAGER',
  CUSTOMER_ADMIN: 'CUSTOMER_ADMIN',
  CUSTOMER_USER: 'CUSTOMER_USER',
  CUSTOMER_RIG: 'CUSTOMER_RIG',
  PREMIUM_MPD_ADMIN: 'PREMIUM_MPD_ADMIN',
  MPD_CUSTOMER_USER: 'MPD_CUSTOMER_USER',
  PREMIUM_MARKETPLACE_ADMIN: 'PREMIUM_MARKETPLACE_ADMIN',
  SELLER_MARKETPLACE_ADMIN: 'SELLER_MARKETPLACE_ADMIN',
  PREMIUM_FIELD_TICKETING_ADMIN: 'PREMIUM_FIELD_TICKETING_ADMIN',
  PREMIUM_FIELD_TICKETING_USER: 'PREMIUM_FIELD_TICKETING_USER',
  PREMIUM_FIELD_TICKETING_MANAGER: 'PREMIUM_FIELD_TICKETING_MANAGER'
};

export const COMPANY_TYPE = {
  PREMIUM: 'PREMIUM',
  CUSTOMER: 'CUSTOMER',
  SELLER: 'SELLER',
};

export const PARTS_CLASSIFICATION = {
  UNCLASSIFIED: '0',
  GREEN: '1',
  GRAY: '2',
};

export const DATAGRID_COLUMN_TYPES = {
  STRING: 'string',
  NUMBER: 'number',
  DATE: 'date',
  DATETIME: 'dateTime',
  BOOLEAN: 'boolean',
  SINGLE_SELECT: 'singleSelect',
  ACTIONS: 'actions',
};

export const CHART_TIMESTAMP_FORMAT = {
  month: '2-digit',
  day: '2-digit',
  hourCycle: 'h23',
  hour: '2-digit',
  minute: '2-digit',
};

export const GRID_COLUMNS_TO_EXCLUDE = [
  'id',
  '_id',
  'grid_id',
  'date',
  'time',
  'DATE',
  'TIME',
  'actuator',
];

export const CONTROLS_TYPE = {
  DATE_PRESETS_SLIDER: 'datePresets',
  SHOPPING_CART: 'shoppingCart',
  EPICOR_CONTROLS: 'epicorControls',
  SEARCH_BOX: 'searchBox',
  APPBAR_SUBTITLE: 'appbarsubtitle',
  EVENT_TAG: 'eventTag',
  ALARMS_CONTROLS: 'alarmsControls',
  DATE_RANGE_SEARCH: 'dateRangeSearch',
  ASPECT_RATIO: 'aspectRatio',
  TRANSACTION_CONTROLS: 'transactionControls',
  MARKETPLACE_TRANSACTION_CONTROLS: 'marketplaceTransactionControls',
  ALARM_HISTORY_CONTROLS: 'alarmHistoryControls',
  TRU_FLOW_CONTROLS: 'truFlowControls',
  PRINT_ORDER: 'printOrder',
  SYNC_MOBILE_DEVICES: 'syncMobileDevices',
  USERS_CONTROLS: 'userControls',
  COMPANY_CONTROLS: 'companyControls',
  WELLS_CONTROLS: 'wellsControls',
  INVENTORY_VIEW: 'inventoryView',
  AUDITLOG_CONTROLS: 'auditLogControls',
  MARKETPLACELOGS_CONTROLS: 'marketplaceLogControls',
  APPBAR_USERNAME: 'appbarUsername',
  ALARM_NOTIFICATION: 'alarmNotification',
  RIGS_CONTROLS: 'rigsControls',
  ASSETTYPE_CONTROLS: 'ASSETTYPE_CONTROLS',
  ENVERUS_WELLS_CONTROLS: 'enverusWellsControls',
  CHECK_INS_CONTROLS: 'checkInsControls',
  TRANSACTION_HISTORY_KPI: 'TRANSACTION_HISTORY_KPI',
  CHECK_INS_KPI: 'CHECK_INS_KPI',
  JOBS_CONTROLS: 'jobsControls',
  FIELDBOXES_KPI_CONTROLS: 'fieldboxesKpiControls',
};

export const PAGES_DATA = [
  {
    label: 'Users',
    link: '/Users',
    roles: [
      USER_TYPE.CUSTOMER_MANAGER,
      USER_TYPE.CUSTOMER_ADMIN,
      USER_TYPE.PREMIUM_ADMIN,
      USER_TYPE.PREMIUM_MARKETPLACE_ADMIN,
      USER_TYPE.SELLER_MARKETPLACE_ADMIN,
    ],
    controls: [CONTROLS_TYPE.USERS_CONTROLS, CONTROLS_TYPE.APPBAR_USERNAME],
  },
  {
    label: 'Companies',
    link: '/Companies',
    roles: [
      USER_TYPE.PREMIUM_ADMIN,
      USER_TYPE.PREMIUM_MARKETPLACE_ADMIN,
      USER_TYPE.SELLER_MARKETPLACE_ADMIN,
      USER_TYPE.CUSTOMER_MANAGER,
      USER_TYPE.CUSTOMER_ADMIN,
      USER_TYPE.CUSTOMER_USER,
      USER_TYPE.CUSTOMER_RIG
    ],
    controls: [CONTROLS_TYPE.COMPANY_CONTROLS, CONTROLS_TYPE.APPBAR_USERNAME],
  },
  {
    label: 'Orders',
    link: '/Orders',
    roles: [USER_TYPE.CUSTOMER_USER, USER_TYPE.CUSTOMER_RIG],
    controls: [CONTROLS_TYPE.ASPECT_RATIO, CONTROLS_TYPE.APPBAR_USERNAME],
  },
  {
    label: 'Quotes',
    link: '/Quotes',
    roles: [USER_TYPE.CUSTOMER_USER, USER_TYPE.CUSTOMER_RIG],
    controls: [CONTROLS_TYPE.ASPECT_RATIO, CONTROLS_TYPE.APPBAR_USERNAME],
  },
  {
    label: 'Shop',
    link: '/Shop',
    roles: [
      USER_TYPE.CUSTOMER_MANAGER,
      USER_TYPE.CUSTOMER_ADMIN,
      USER_TYPE.CUSTOMER_USER,
      USER_TYPE.CUSTOMER_RIG,
      USER_TYPE.PREMIUM_ADMIN,
      USER_TYPE.PREMIUM_MARKETPLACE_ADMIN,
      USER_TYPE.SELLER_MARKETPLACE_ADMIN,
    ],
    controls: [
      CONTROLS_TYPE.SHOPPING_CART,
      CONTROLS_TYPE.SEARCH_BOX,
      CONTROLS_TYPE.APPBAR_SUBTITLE,
      CONTROLS_TYPE.INVENTORY_VIEW,
      CONTROLS_TYPE.APPBAR_USERNAME
    ],
  },
  {
    label: 'Jobs',
    link: '/Jobs',
    selected: true,
    roles: [
      USER_TYPE.PREMIUM_ADMIN,
      USER_TYPE.PREMIUM_MPD_ADMIN,
      USER_TYPE.MPD_CUSTOMER_USER,
    ],
    controls: [CONTROLS_TYPE.SEARCH_BOX, CONTROLS_TYPE.JOBS_CONTROLS, CONTROLS_TYPE.APPBAR_USERNAME],
  },
  {
    label: 'Alarms',
    link: '/Alarms',
    roles: [USER_TYPE.PREMIUM_ADMIN, USER_TYPE.PREMIUM_MPD_ADMIN],
    controls: [CONTROLS_TYPE.ALARMS_CONTROLS, CONTROLS_TYPE.APPBAR_USERNAME],
  },
  {
    label: 'Data',
    link: '/Data',
    roles: [
      USER_TYPE.PREMIUM_ADMIN,
      USER_TYPE.PREMIUM_MPD_ADMIN,
      USER_TYPE.MPD_CUSTOMER_USER,
    ],
    controls: [
      CONTROLS_TYPE.DATE_PRESETS_SLIDER,
      CONTROLS_TYPE.ALARM_NOTIFICATION,
      CONTROLS_TYPE.EVENT_TAG,
      CONTROLS_TYPE.ASPECT_RATIO,
      CONTROLS_TYPE.SPEED_CHART,
      CONTROLS_TYPE.APPBAR_USERNAME
    ],
  },
  {
    label: 'Assets',
    roles: [USER_TYPE.PREMIUM_ADMIN],
    submenu: [
      {
        label: 'Types',
        link: '/Assets/Types',
        controls: [
          CONTROLS_TYPE.SEARCH_BOX,
          CONTROLS_TYPE.ASSETTYPE_CONTROLS,
          CONTROLS_TYPE.APPBAR_USERNAME
        ],
      },
      {
        label: 'Assets',
        link: '/Assets/Records',
      },
    ],
  },
  {
    label: 'Enverus',
    roles: [USER_TYPE.PREMIUM_ADMIN],
    submenu: [
      {
        label: 'Rigs',
        link: '/Enverus/Rigs',
        controls: [
          CONTROLS_TYPE.SEARCH_BOX,
          CONTROLS_TYPE.RIGS_CONTROLS,
          CONTROLS_TYPE.APPBAR_USERNAME,
        ],
      },
      {
        label: 'Wells',
        link: '/Enverus/Wells',
        controls: [
          CONTROLS_TYPE.ENVERUS_WELLS_CONTROLS,
          CONTROLS_TYPE.APPBAR_USERNAME
        ],
      },
    ],
  },
  {
    label: 'TruFlowSettings',
    link: '/TruFlowSettings',
    roles: [
      USER_TYPE.PREMIUM_ADMIN,
      USER_TYPE.PREMIUM_MPD_ADMIN,
      USER_TYPE.MPD_CUSTOMER_USER,
    ],
    controls: [
      CONTROLS_TYPE.APPBAR_SUBTITLE,
      CONTROLS_TYPE.TRU_FLOW_CONTROLS,
      CONTROLS_TYPE.APPBAR_USERNAME
    ],
  },
  {
    label: 'Epicor',
    roles: [USER_TYPE.PREMIUM_ADMIN, USER_TYPE.PREMIUM_FIELD_TICKETING_ADMIN, USER_TYPE.PREMIUM_FIELD_TICKETING_MANAGER],
    submenu: [
      {
        label: 'Customers',
        link: '/Epicor/Customers',
        controls: [CONTROLS_TYPE.EPICOR_CONTROLS],
      },
      {
        label: 'Parts',
        link: '/Epicor/Parts',
        controls: [CONTROLS_TYPE.EPICOR_CONTROLS],
      },
      {
        label: 'Price Lists',
        link: '/Epicor/PriceLists',
        controls: [CONTROLS_TYPE.EPICOR_CONTROLS],
      },
      {
        label: 'Sale Orders',
        link: '/Epicor/SaleOrders',
        controls: [CONTROLS_TYPE.EPICOR_CONTROLS],
      },
      {
        label: 'Workforce',
        link: '/Epicor/Workforce',
        controls: [CONTROLS_TYPE.EPICOR_CONTROLS],
      },
      {
        label: 'Bins',
        link: '/Epicor/Bins',
        controls: [CONTROLS_TYPE.EPICOR_CONTROLS],
      },
    ],
  },
  {
    label: 'Field Tickets',
    link: '/FieldTickets',
    roles: [
      USER_TYPE.PREMIUM_ADMIN,
      USER_TYPE.PREMIUM_FIELD_TICKETING_ADMIN,
      USER_TYPE.PREMIUM_FIELD_TICKETING_USER,
      USER_TYPE.PREMIUM_FIELD_TICKETING_MANAGER
    ],
    controls: [
      CONTROLS_TYPE.APPBAR_SUBTITLE,
      CONTROLS_TYPE.PRINT_ORDER,
      CONTROLS_TYPE.ASPECT_RATIO,
      CONTROLS_TYPE.APPBAR_USERNAME
    ],
  },
  {
    label: 'Field Boxes',
    link: '/FieldBoxes',
    roles: [
      USER_TYPE.PREMIUM_ADMIN,
      USER_TYPE.PREMIUM_FIELD_TICKETING_ADMIN,
      USER_TYPE.PREMIUM_FIELD_TICKETING_USER,
      USER_TYPE.PREMIUM_FIELD_TICKETING_MANAGER
    ],
    controls: [
      CONTROLS_TYPE.SEARCH_BOX,
      CONTROLS_TYPE.SYNC_MOBILE_DEVICES,
      CONTROLS_TYPE.APPBAR_USERNAME
    ],
  },
  {
    label: 'Wells',
    link: '/Wells',
    roles: [
      USER_TYPE.PREMIUM_ADMIN,
      USER_TYPE.PREMIUM_MPD_ADMIN,
      USER_TYPE.MPD_CUSTOMER_USER
    ],
    controls: [
      CONTROLS_TYPE.SEARCH_BOX,
      CONTROLS_TYPE.WELLS_CONTROLS,
      CONTROLS_TYPE.APPBAR_USERNAME,
    ],
  },
  {
    label: 'Check Ins',
    link: '/CheckIns',
    roles: [
      USER_TYPE.PREMIUM_ADMIN,
      USER_TYPE.PREMIUM_FIELD_TICKETING_ADMIN,
      USER_TYPE.PREMIUM_FIELD_TICKETING_MANAGER
    ],
    controls: [
      CONTROLS_TYPE.CHECK_INS_CONTROLS,
      CONTROLS_TYPE.APPBAR_USERNAME
    ],
  },
  {
    label: 'Trucks',
    link: '/Trucks',
    roles: [
      USER_TYPE.PREMIUM_ADMIN,
      USER_TYPE.PREMIUM_FIELD_TICKETING_ADMIN,
      USER_TYPE.PREMIUM_FIELD_TICKETING_USER,
      USER_TYPE.PREMIUM_FIELD_TICKETING_MANAGER
    ],
    controls: [
      CONTROLS_TYPE.APPBAR_SUBTITLE,
      CONTROLS_TYPE.ASPECT_RATIO,
      CONTROLS_TYPE.APPBAR_USERNAME
    ],
  },
  {
    label: 'Audit Log',
    link: '/AuditLog',
    roles: [USER_TYPE.PREMIUM_ADMIN],
    controls: [CONTROLS_TYPE.AUDITLOG_CONTROLS, CONTROLS_TYPE.APPBAR_USERNAME],
  },
  {
    label: 'Marketplace Log',
    link: '/MarketplaceLog',
    roles: [USER_TYPE.PREMIUM_ADMIN],
    controls: [CONTROLS_TYPE.MARKETPLACELOGS_CONTROLS, CONTROLS_TYPE.APPBAR_USERNAME],
  },
  {
    label: 'Transaction History',
    link: '/TransactionHistory',
    roles: [USER_TYPE.PREMIUM_ADMIN, USER_TYPE.PREMIUM_FIELD_TICKETING_ADMIN, USER_TYPE.PREMIUM_FIELD_TICKETING_USER, USER_TYPE.PREMIUM_FIELD_TICKETING_MANAGER],
    controls: [CONTROLS_TYPE.TRANSACTION_CONTROLS, CONTROLS_TYPE.APPBAR_USERNAME],
  },
  {
    label: 'Alarm History',
    link: '/AlarmHistory',
    roles: [USER_TYPE.PREMIUM_ADMIN],
    controls: [CONTROLS_TYPE.ALARM_HISTORY_CONTROLS, CONTROLS_TYPE.APPBAR_USERNAME],
  },
  {
    label: 'Marketplace Transactions',
    link: '/MarketplaceTransactions',
    roles: [USER_TYPE.PREMIUM_ADMIN, USER_TYPE.PREMIUM_MARKETPLACE_ADMIN],
    controls: [CONTROLS_TYPE.MARKETPLACE_TRANSACTION_CONTROLS, CONTROLS_TYPE.APPBAR_USERNAME],
  },
  {
    link: '/TransactionHistory/KPIs',
    roles: [USER_TYPE.PREMIUM_ADMIN, USER_TYPE.PREMIUM_FIELD_TICKETING_ADMIN, USER_TYPE.PREMIUM_FIELD_TICKETING_MANAGER],
    controls: [CONTROLS_TYPE.TRANSACTION_HISTORY_KPI, CONTROLS_TYPE.APPBAR_USERNAME, CONTROLS_TYPE.APPBAR_SUBTITLE],
  },
  {
    link: '/CheckIns/KPIs',
    roles: [USER_TYPE.PREMIUM_ADMIN, USER_TYPE.PREMIUM_FIELD_TICKETING_ADMIN, USER_TYPE.PREMIUM_FIELD_TICKETING_MANAGER],
    controls: [CONTROLS_TYPE.CHECK_INS_KPI, CONTROLS_TYPE.APPBAR_USERNAME, CONTROLS_TYPE.APPBAR_SUBTITLE],
  },
  {
    link: '/FieldBoxes/KPIs',
    roles: [USER_TYPE.PREMIUM_ADMIN, USER_TYPE.PREMIUM_FIELD_TICKETING_ADMIN, USER_TYPE.PREMIUM_FIELD_TICKETING_MANAGER],
    controls: [CONTROLS_TYPE.FIELDBOXES_KPI_CONTROLS, CONTROLS_TYPE.APPBAR_USERNAME, CONTROLS_TYPE.APPBAR_SUBTITLE],
  },
  { link: '/PrivacyPolicy', roles: ['*'] },
  { link: 'Unauthorized', roles: ['*'] },
];

export const UNASSIGNED_ASSET_JOB_ID = '-1';

export const JOBS_SYSTEM_STATUS = {
  NO_ACTUATOR_DATA: -1,
  NONE: 0,
  FAULTS_ONLY: 1,
  WARNINGS_ONLY: 2,
  BOTH: 3,
};

export const FIELDBOX_LASTCHANGED = {
  STATUS_ONE: 1,
  STATUS_TWO: 2,
  STATUS_THREE: 3,
};

export const CALENDER_PROPS = {
  format: 'MM/dd/yyyy HH:mm',
  timeSteps: { hours: 1, minutes: 1 },
  ampm: false,
  desktopModeMediaQuery: '@media (pointer: none)',
};

export const DEFAULT_PRESET = 120;
export const DEFAULT_MOBILE_PRESET = 360;
export const DEFAULT_MINUTES_PRESET = 15;

export const HOURS_RANGE_PRESETS = [
  {
    label: '2hrs',
    value: 120,
  },
  {
    label: '4hrs',
    value: 240,
  },
  {
    label: '6hrs',
    value: 360,
  },
  {
    label: '8hrs',
    value: 480,
  },
];

export const MINUTES_RANGE_PRESETS = [
  {
    label: '2mins',
    value: 2,
  },
  {
    label: '15mins',
    value: 15,
  },
  {
    label: '30mins',
    value: 30,
  },
  {
    label: '45mins',
    value: 45,
  },
  {
    label: '1h',
    value: 60,
  },
];

export const DRILLING_FILTERS = [
  {
    id: 0,
    header: 'MPD',
    fields: [
      {
        fieldName: 'Pump Pressure',
        column: 'SPPA',
        color: '75757599',
      },
      {
        fieldName: 'Choke Pressure',
        column: 'SBP1',
        color: '2B78EA',
      },
      {
        fieldName: 'Choke Position',
        column: 'SPNRNG1',
        color: '00000099',
      },
      {
        fieldName: 'Measured Depth',
        column: 'DEPTMEAS',
        color: 'FF0000',
      },
    ],
  },
  {
    id: 1,
    header: 'Drilling Performance',
    fields: [
      {
        fieldName: 'WOB',
        column: 'WOBA',
        color: '99C13B',
      },
      {
        fieldName: 'ROP',
        column: 'ROPA',
        color: '2B78EA',
      },
      {
        fieldName: 'RPM',
        column: 'RPMA',
        color: '00000042',
      },
      {
        fieldName: 'Torque',
        column: 'TORQA',
        color: '00000073',
      },
      {
        fieldName: 'Measured Depth',
        column: 'DEPTMEAS',
        color: 'FF0000',
      },
    ],
  },
  {
    id: 2,
    header: 'Custom',
    fields: [],
  },
];

export const ACTUATOR_FILTERS = [
  {
    id: 0,
    header: 'Custom',
    fields: [
      {
        column: '~1_bMotorEnableState',
        fieldName: 'Motor State',
        color: '00000033',
      },
      {
        column: '~1_eCurrentControlMode',
        fieldName: 'Control Mode',
        color: '000000',
      },
      {
        column: '~1_rActualTorque',
        fieldName: 'Torque',
        color: 'FF000080',
      },
      {
        column: '~1_rDesiredPressure',
        fieldName: 'Pressure Setpoint',
        color: '2348ED',
      },
      {
        column: '~1_rMeasuredPressure',
        fieldName: 'Measured Pressure',
        color: '3BA5D682',
      },
      {
        column: '~1_rSpanPercentage',
        fieldName: 'Choke Position',
        color: '000000B3',
      },
    ],
  },
];

export const DEFAULT_ACTUATOR_FILTERS = [
  {
    id: 0,
    header: 'Custom',
    fields: [
      {
        column: 'bMotorEnableState',
        fieldName: 'Motor State',
        color: '00000033',
      },
      {
        column: 'eCurrentControlMode',
        fieldName: 'Control Mode',
        color: '000000',
      },
      {
        column: 'rActualTorque',
        fieldName: 'Torque',
        color: 'FF000080',
      },
      {
        column: 'rDesiredPressure',
        fieldName: 'Pressure Setpoint',
        color: '2348ED',
      },
      {
        column: 'rMeasuredPressure',
        fieldName: 'Measured Pressure',
        color: '3BA5D682',
      },
      {
        column: 'rSpanPercentage',
        fieldName: 'Choke Position',
        color: '000000B3',
      },
    ],
  },
];

export const ACTUATOR_REPORT = [
  {
    id: 0,
    header: 'MPD Utilization',
    fields: [
      {
        fieldName: 'Specify date range and click on the Report button',
      },
    ],
  },
  {
    id: 1,
    header: 'RCD (Bearing Assy)',
    fields: [
      {
        fieldName:
          'Specify date range when bearing assembly was installed and removed then click on the Report button',
      },
    ],
  },
];

export const DEFAULT_DRILLING_FILTERS = [
  {
    id: 0,
    header: 'MPD',
    fields: [
      {
        fieldName: 'Pump Pressure',
        column: 'SPPA',
        color: '75757599',
        number: false
      },
      {
        fieldName: 'Choke Pressure',
        column: 'SBP',
        color: '2B78EA',
        number: true
      },
      {
        fieldName: 'Choke Position',
        column: 'SPNRNG',
        color: '00000099',
        number: true
      },
      {
        fieldName: 'Measured Depth',
        column: 'DEPTMEAS',
        color: 'FF0000',
        number: false
      },
    ],
  },
];

export const OPERATOR_SYMBOLS_PRESET = [
  {
    operator: '<',
    label: 'lessThan'
  },
  {
    operator: '<=',
    label: 'lessThanEquals'
  },
  {
    operator: '>',
    label: 'greaterThan'
  },
  {
    operator: '>=',
    label: 'greaterThanEquals'
  },
  {
    operator: '=',
    label: 'equals'
  },
  {
    operator: '!=',
    label: 'notEquals'
  },
];

export const DEVICE_CLASSIFICATION = {
  DESKTOP: 1,
  TABLET: 2,
  MOBILE: 3,
};

export const WELLS_DRILL_STRING_VALIDATION = {
  name: 'Name should be limited to 100 characters',
  od: 'OD should be a number larger than ID',
  id: 'ID should be a number greater than or equal to 1.0 inch',
  tj_od: 'Tooljoint OD should be a number greater than or equal to 0.0',
  tj_id: 'Tooljoint ID should be a number greater than or equal to 0.0',
  len: 'Length should be a number greater than or equal to 0.0',
  tj_len: 'Tooljoint length percentage should be a number between 0 and 15',
  tj_len_non_zero_tj_id: 'Tooljoint ID should be less than or equal to ID when Tooljoint length percentage is not zero',
  tj_len_non_zero_tj_od: 'Tooljoint OD should be greater than or equal to OD when Tooljoint length percentage is not zero',
  tj_len_min_tj_id: 'Tooljoint ID should be greater than or equal to 1.0 inch when Tooljoint length percentage is not zero'
};

export const WELLS_DRILL_FLUID_VALIDATION = {
  MW: 'Mud weight should be greater than 5.0 ppg',
  FANN_300: 'Fann 300 should be less than or equal to Fann 600',
  FANN_200: 'Fann 200 should be less than or equal to Fann 300',
  FANN_100: 'Fann 100 should be less than or equal to Fann 200',
  FANN_6: 'Fann 6 should be less than or equal to Fann 100',
  FANN_3: 'Fann 3 should be greater than 0 and less than or equal to Fann 6',
  GEL_10MIN: 'Gel 10 min should be greater than 0 and greater than or equal to Gel 10 sec',
  GEL_10SEC: 'Gel 10 sec should be greater than 0',
  TANK_VOL: 'Tank volume should be a number greater than or equal to 0'
};

export const WELLS_GEOMETRY_TYPE = ['Riser', 'Casing', 'Liner', 'Open Hole'];

export const WELLS_DRILL_STRING_BIT_TYPE = ['PDC', 'Roller Cone'];

export const MAP_PIN_SIZE = {
  desktop: [25, 35],
  mobile: [12, 16]
};

export const MKT_ACTIONS = {
  PAGE_LOADED: 'PAGE_LOADED'
};

export const FIELDBOX_KPI_DIVISION_MAP = {
  APPALACHIA: ['APP'],
  ROCKIES: ['UTAH', 'CAS', 'ROCKIES'],
  CALIFORNIA: ['CALI'],
  'EAST TEXAS/LOUISIANA': ['CSTAT', 'NTX'],
  'NORTH DAKOTA': ['ND'],
  'WEST TEXAS': ['WTX', 'NMEX'],
  OKLAHOMA: ['OK'],
  'SOUTH TEXAS': ['STX']
};

export const DIVISIONS = [
  'ALL',
  'HOUSTON HQ',
  'WEST TEXAS',
  'NORTH TEXAS',
  'SOUTH TEXAS',
  'OKLAHOMA',
  'ROCKIES',
  'NORTH DAKOTA',
  'APPALACHIA',
  'CALIFORNIA'];

export const PREMIUM_SOCIALS = {
  linkedin: 'https://www.linkedin.com/company/premium-oilfield-supply/',
  youtube: 'https://www.youtube.com/@premiumoilfieldtechnologie8675',
  instagram: 'https://www.instagram.com/premiumoilfieldtechnologies/',
  facebook: 'https://www.facebook.com/premiumoilfield/'
};

export const HYDRAULICS_RUN_DETAILS = {
  BIT_DEPTH: 'Bit depth should be greater than or equal to 0',
  HOLE_DEPTH: 'Hole depth should be greater than or equal to 0',
  ROP: 'Rop should be greater than or equal to 0',
  FLOW_IN: 'Flow rate should be greater than or equal to 0',
  SBP: 'SBP should br greater than or equal to 0'
};

export const DEFAULT_SCREEN_VIEW_MODE = {
  aspectRatioPage: { maxWidth: 'xl', style: { padding: 0 } },
  aspectChanged: true
};

export const MKT_STORYBOOK_DESCRIPTION = [
  {
    description: 'The Rig Manager was worried that his rig would stop making hole because he used cheap mud pump expendables and other low-quality components from those other companies that gifted him a silly trucker hat!'
  },
  {
    description: '... but then he remembered that he could buy awesome products and MRO at affordable prices from Premium MKT, 24 hours a day, 7 days a week, 365 days a year, using his mobile phone, tablet, or computer!'
  },
  {
    description: 'Premium MKT has rig managers’ backs, replacing low-quality items from those other companies so that rigs can keep making hole quickly and efficiently all the way to TD!'
  }
];
