/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { Grid, Typography, IconButton, gridClasses } from '@mui/material';
import ModalDialog from '../ui/ModalDialog/index';
import { CancelPresentationRounded, ContentCopyOutlined, DeleteRounded, EditOutlined, SaveRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import Datagrid from '../ui/Datagrid';
import columns from './table_config';
import { renderCell } from '../ui/Datagrid/table_config';
import { csvFileToArray } from '../../utils/csvFileToArray';
import styles from './style';
import ReactJson from 'react-json-view';
import { addNewDrillStringRow, copyDrillStringRow, createDrillString, deleteDrillStringRow, importDrillStringDetails, listDrillString, listDrillStringBitDetails, listDrillStringDetails, updateDrillString, updateDrillStringDetails } from '../../store/actions/wellsDrillString';
import DrillStringDetails from './drillStringDetails';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { enqueueSnackbar } from '../../store/actions/snackbar';
import SelectEditInputTextField from '../ui/SelectEditInputTextField';
import DrillStringEditModal from './drillStringEditModal';
import DrillStringBitDetails from './drillStringBit';
import DeleteConfirmationModal from '../deleteConfirmationModal';
const DrillStringModal = ({
  onCloseClick,
  drillStringData,
  isOpen
}) => {
  const dispatch = useDispatch();
  const classes = styles();
  const drillString = useSelector((state) => state.wellsDrillString);
  const apiRef = useGridApiRef();
  const fileReader = new FileReader();
  const [editData, setEditData] = useState({});
  const [properties, setProperties] = useState([]);
  const [invalidRows, setInvalidRows] = useState({});
  const [showDrillStringModal, setShowDrillStringModal] = useState(false);
  const [showBHADialog, setShowBHADialog] = useState(false);
  const [showBITDialog, setShowBITDialog] = useState(false);
  const [deleteAction, setDeleteAction] = useState(false);
  const [deleteRow, setDeleteRow] = useState();

  useEffect(() => {
    if (!drillString.loading) {
      dispatch(listDrillString(drillStringData?.row?._id));
    }
  }, []);

  useEffect(() => {
    if (drillString?.data) {
      setProperties(drillString?.data);
    }
  }, [drillString?.data]);

  useEffect(() => {
    if (properties[0]?.name === '') {
      const id = properties[0]._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 0
        });
        apiRef.current.setCellMode(id, 'name', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'name');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties?.length]);

  const handleAddDrillString = () => {
    dispatch(addNewDrillStringRow());
  };

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {drillStringData?.row?.name || ''}
          </Grid>
          <Grid item>
          <IconButton
              onClick={onCloseClick}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const removeSurvey = (row) => {
    if (row?.isNewRow) {
      const updatedDrillString = properties.filter(item => item._id !== row?._id);
      setProperties(updatedDrillString);
      dispatch(listDrillString(drillStringData?.row?._id));
    } else {
      dispatch(deleteDrillStringRow(row?._id, drillStringData?.row?._id));
    }
    setDeleteAction(false);
    setDeleteRow({});
  };

  const handleSave = (row) => {
    if (row?.MD && row?.name) {
      const body = {
        ...row,
        MD: Number(row?.MD)
      };
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      if (row?.isNewRow) {
        dispatch(createDrillString(body, drillStringData?.row?._id));
      } else {
        dispatch(updateDrillString({ ...row, MD: Number(row?.MD), wellsInfoId: drillStringData?.row?._id }));
      }
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          !row?.MD ? 'Please enter MD details' : 'Please enter name',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const renderActionsCell = ({ row }) => {
    const hasNewRow = properties?.some(items => items?.isNewRow);
    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          disabled={drillString?.loading || row?.MD < 0 || hasNewRow}
          onClick={() => {
            setShowDrillStringModal(true);
            setEditData(row);
          }}
        >
          <EditOutlined />
        </IconButton>
        <IconButton
          disabled={drillString.loading}
          variant="solid"
          onClick={() => {
            handleSave(row);
          }}
        >
          <SaveRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={row?.isNewRow}
          onClick={() => {
            dispatch(copyDrillStringRow(row));
          }}
        >
          <ContentCopyOutlined />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={drillString.loading}
          onClick={() => {
            setDeleteAction(true);
            setDeleteRow(row);
          }}
        >
          <DeleteRounded />
        </IconButton>
      </Grid>
    );
  };
  const uploadCSVFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        const drillStringList = csvFileToArray(csvOutput);
        dispatch(importDrillStringDetails(drillStringList));
      };
      fileReader.readAsText(file);
    }
  };

  const RenderJsonField = ({ value }) => {
    const data = useRef(value);

    return (
      <ReactJson
        src={data.current}
        name={false}
        sortKeys={true}
        collapsed={true}
        displayDataTypes={false}
        quotesOnKeys={false}
      />
    );
  };

  const updateData = (id, field, value) => {
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          return {
            ...item,
            [field]: value
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };

  const renderValueCell = (params) => {
    return <SelectEditInputTextField numberFormatOnly {...params} updateData={updateData} />;
  };

  const renderValueTextCell = (params) => {
    return <SelectEditInputTextField {...params} updateData={updateData} />;
  };

  if (apiRef.current === null) apiRef.current = {};
  return (
    <ModalDialog
      title={getModalTitle()}
      isOpen={isOpen}
      onClose={onCloseClick}
      dialogClass={classes.dialogModal}
    >
      <Grid container>
      <Grid item className={classes.customTool} xs={1} justifyContent="space-between" >
            <Grid item>
              <Typography variant='button' fontWeight='700' className={drillString.loading || properties[0]?.isNewRow ? classes.disableIcon : ''} onClick={handleAddDrillString}>+ Add</Typography>
            </Grid>
            <Grid item>
              <label className={classes.importLabel} htmlFor='getCSVFile'>Import</label>
              <input type='file' id='getCSVFile' accept={'.csv'} style={{ display: 'none' }} onChange={uploadCSVFile} />
            </Grid>
          </Grid>
        <Grid item xs={12}>
          <Datagrid
            apiRef={apiRef}
            data={properties}
            loading={drillString.loading}
            columns={columns(renderCell, RenderJsonField, renderActionsCell, renderValueCell, renderValueTextCell)}
            onRowClick={(params, evt) => {
              evt.preventDefault();
              evt.stopPropagation();
            }}
            disableStickyHeader={false}
            getRowClassName={(params) => {
              return invalidRows[params.id] ? classes.invalidRow : '';
            }}
            autoHeight={false}
            getRowId={(row) => row._id}
            getRowHeight={() => 'auto'}
            sx={{
              marginLeft: '-6px',
              '.MuiDataGrid-cell': {
                fontSize: '1rem',
                fontWeight: 900
              },
              '.MuiDataGrid-columnHeaderTitle': {
                fontSize: '1.2rem',
                fontWeight: '900 !important',
              },
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
            autoheight
            editMode="cell"
            height={'50vh'}
            pageSize={100}
            toolbar={{
              options: {
                columns: false,
                filters: false,
                density: false,
                export: false
              },
            }}
          />
        </Grid>
        {showDrillStringModal
          ? (
        <DrillStringEditModal
          drillStringData={drillStringData}
          setShowBHADialog={() => {
            if (!editData?.isNewRow) {
              dispatch(listDrillStringDetails(editData?._id));
            } else {
              delete editData.isNewRow;
              dispatch(createDrillString(editData, drillStringData?.row?._id, 'details'));
              dispatch({
                type: REDUX_ACTIONS.WELLS_DRILL_STRING_DETAILS_LIST_SUCCESS,
                payload: {
                  data: [],
                  paginatedElements: 0
                }
              });
              const newProperties = properties.map((item) => item._id === editData._id ? editData : item);
              const updatedData = {
                ...editData,
                _id: drillString?.drillStringId ? drillString?.drillStringId : editData?._id,
                drillstring_details: newProperties
              };
              dispatch(updateDrillStringDetails(updatedData));
            }
            setShowBHADialog(true);
          }}
          setShowBITDialog={() => {
            if (!editData?.isNewRow) {
              dispatch(listDrillStringBitDetails(editData?._id));
            } else {
              delete editData.isNewRow;
              dispatch(createDrillString(editData, drillStringData?.row?._id, 'details'));
              dispatch({
                type: REDUX_ACTIONS.WELLS_DRILL_STRING_BIT_DETAILS_LIST_SUCCESS,
                payload: {
                  data: [],
                  paginatedElements: 0
                }
              });
              const updatedData = {
                ...editData,
                _id: drillString?.drillStringId ? drillString?.drillStringId : editData?._id,
                bit_details: {}
              };
              dispatch(updateDrillStringDetails(updatedData));
            }
            setShowBITDialog(true);
          }}
          open={showDrillStringModal}
          handleClose={() => {
            setShowDrillStringModal(false);
          }}
        />
            )
          : null}
          {showBHADialog && editData?.MD
            ? (
        <DrillStringDetails
          drillStringData={drillStringData}
          open={showBHADialog}
          handleClose={() => {
            setShowBHADialog(false);
            setShowDrillStringModal(false);
            setEditData({});
            dispatch({
              type: REDUX_ACTIONS.WELLS_DRILL_STRING_SET_ID,
              payload: {
                data: '',
              },
            });
          }}
          data={editData}
        />
              )
            : null}
      {showBITDialog && editData?.MD
        ? (
        <DrillStringBitDetails
          drillStringData={drillStringData}
          open={showBITDialog}
          handleClose={() => {
            setShowBITDialog(false);
            setShowDrillStringModal(false);
            setEditData({});
            dispatch({
              type: REDUX_ACTIONS.WELLS_DRILL_STRING_SET_ID,
              payload: {
                data: '',
              },
            });
          }}
          data={editData}
        />
          )
        : null}
        {
          deleteAction && <DeleteConfirmationModal
            open={deleteAction}
            handleDelete={removeSurvey}
            handleClose={setDeleteAction}
            deleteRow={deleteRow}
          />
        }
      </Grid>
    </ModalDialog>
  );
};
DrillStringModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  drillStringData: PropTypes.object,
  isOpen: PropTypes.bool.isRequired
};
DrillStringModal.defaultProps = {
  drillStringData: {}
};
export default DrillStringModal;
